import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const TheContainer = () =>
    import ('@/containers/TheContainer')

// Views
const Dashboard = () =>
    import ('@/views/Dashboard')

const allWithdraw = () =>
    import ('@/views/withdraw/List')
const pendinWithdraw = () =>
    import ('@/views/withdraw/Pending')
const approveWithdraw = () =>
    import ('@/views/withdraw/Approve')
const rejectWithdraw = () =>
    import ('@/views/withdraw/Reject')

const allUser = () =>
    import ('@/views/user-signup/List')
const activeUser = () =>
    import ('@/views/user-signup/ActiveList')
const blockUser = () =>
    import ('@/views/user-signup/BlockList')
const profile = () =>
    import ('@/views/user-signup/Form')

const setting = () =>
    import ('@/views/setting/Form')
const update = () =>
    import ('@/views/update/Form')
const updateLink = () =>
    import ('@/views/update-link/Form')
const paymentMethod = () =>
    import ('@/views/pay-method/List')

// Views - Pages
const Page404 = () =>
    import ('@/views/pages/Page404')
const Page500 = () =>
    import ('@/views/pages/Page500')
const Login = () =>
    import ('@/views/pages/Login')
const Register = () =>
    import ('@/views/pages/Register')

Vue.use(Router)

const routes = [{
        path: '/',
        redirect: '/dashboard',
        name: 'Home',
        component: TheContainer,
        children: [{
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'withdraw',
                redirect: '/base/cards',
                name: 'Withdraw',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'all',
                        name: 'Popovers',
                        component: allWithdraw,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'approve',
                        name: 'Popovers',
                        component: approveWithdraw,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'pending',
                        name: 'Popovers',
                        component: pendinWithdraw,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'reject',
                        name: 'Popovers',
                        component: rejectWithdraw,
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'users',
                redirect: '/base/cards',
                name: 'Users',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'all',
                        name: 'Popovers',
                        component: allUser,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'active',
                        name: 'Popovers',
                        component: activeUser,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'activeusers',
                        name: 'ActiveNow',
                        component: () => import ('@/views/user-signup/ActiveNow'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'block',
                        name: 'Popovers',
                        component: blockUser,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'profile',
                        name: 'Popovers',
                        component: profile,
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'other',
                redirect: '/base/cards',
                name: 'Other',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'setting',
                        name: 'Popovers',
                        component: setting,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'update',
                        name: 'Popovers',
                        component: update,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'link-update',
                        name: 'Popovers',
                        component: updateLink,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'article-link',
                        name: 'Article Link',
                        component: () => import ('@/views/article-link/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'follow-link',
                        name: 'Follow Link',
                        component: () => import ('@/views/follow-link/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'game-link',
                        name: 'Popovers',
                        component: () => import ('@/views/game-link/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'video-link',
                        name: 'Popovers',
                        component: () => import ('@/views/video-link/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'youtube-link',
                        name: 'Popovers',
                        component: () => import ('@/views/youtube-link/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'movie-video-link',
                        name: 'Popovers',
                        component: () => import ('@/views/movie-link/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'extra-video-link',
                        name: 'Popovers',
                        component: () => import ('@/views/extra-link/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'used-ip-list',
                        name: 'Popovers',
                        component: () => import ('@/views/ip-list/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'unused-ip-list',
                        name: 'Popovers',
                        component: () => import ('@/views/ip-list/UnUsedList'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'payment-method',
                        name: 'PaymentMethod',
                        component: paymentMethod,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'notifications',
                        name: 'Notification',
                        component: () => import ('@/views/notification/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'send-notification',
                        name: 'SendNotification',
                        component: () => import ('@/views/send-notification/Form'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'advertisement',
                        name: 'advertisement',
                        component: () => import ('@/views/advertisement/Form'),
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '404',
                name: 'Page404',
                component: Page404,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '500',
                name: 'Page500',
                component: Page500,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: 'login',
                name: 'Login',
                component: Login,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: 'register',
                name: 'Register',
                component: Register,
                meta: {
                    requiresAuth: false
                }
            }
        ]
    }
]


const router = new Router({
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/pages/login')
    } else {
        next()
    }
})

export default router